import { createSlice } from "@reduxjs/toolkit";


let initialState = {
    selectedEmpsForTimeSheetApprove :[],
    user:{}
    
}



const WRMSAppSlice = createSlice({
    name:'WRMSAppSlice',
    initialState,
    reducers:{
        setSelectedEmpsForTimeSheetApprove : (state , action)=>{
            state.selectedEmpsForTimeSheetApprove = action?.payload
        },
        setLoggedinUser:(state , action)=>{
            state.user= action.payload
        }
    }
})

export const {setSelectedEmpsForTimeSheetApprove , setLoggedinUser}=WRMSAppSlice.actions
export default WRMSAppSlice.reducer