import React, { useEffect, useState, useLayoutEffect } from 'react'
import { URLS } from '../../helpers/URLS';
import { useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import AdminHeader from '../AdminHeader/AdminHeader';
import AdminNav from '../AdminNav/AdminNav';
import useAxios from '../../helpers/Hooks/useAxios';
import './Dashboard.scss';
import { useDispatch } from 'react-redux';
import { setLoggedinUser } from '../../Redux/reducer';

const Header = () => {

    const { fetchData } = useAxios();
    const dispatch=useDispatch()

    const navigate = useNavigate();

    const [toggle, setToggle] = useState(true);

    const [user, setUser] = useState({
        employee: {
            employeeCode: "",
            employeeName: "",
            id: "",
            totalData: {}
        },
        roles: {
            daily: false,
            isTeam: false,
            isProjects: false
        },
        menu: []
    })

    const getUserProfle = async () => {
        const resp = await fetchData({ url: URLS.user, method: 'get' });
        if (resp.status === 200) {
            setUser(resp.data)
            dispatch(setLoggedinUser(resp.data))
        } else {
            navigate("/")
        }
    }

    useEffect(() => {
        getUserProfle();
    }, [])

    useLayoutEffect(() => {
        window.addEventListener('resize', () => {
            setToggle(window.innerWidth > 1025 ? true : false);
        });
    }, [])

    return (
        <div className='dashboard_section'>
            <div className='admin-bg d-flex p-0'>
                <AdminNav setToggle={setToggle} toggle={toggle} user={user.employee} menu={user.menu} />
                <div id="content" className={`s${toggle ? '' : 'active'}`} style={{ backgroundColor: " bg-light" }}>
                    <AdminHeader setToggle={setToggle} toggle={toggle} user={user} />
                    <div className='container py-3 pe-0 p-lg-3 outlet_scroll'>
                        <Outlet />
                    </div>
                </div>
            </div>
            {toggle && <div className='mobile-sidebar-overlay-container' onClick={() => setToggle(false)}></div>}
        </div>
    )
}

export default Header;
