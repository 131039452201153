import React, { useEffect, useState } from "react";
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { Box, Button, TextField, Typography, IconButton, Tooltip, ListItem } from "@mui/material";
// import { Container } from "react-bootstrap";
import "./style.scss";
import AddStatus from "../DailyStatus/AddStatus";
import DeleteIcon from '@mui/icons-material/Delete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from "react-router-dom";
import TeamTimeSheets from "../../components/TeamTimeSheets";
import { Image } from "react-bootstrap";
import commtImage from '../../assets/images/comments.png'
import { useSelector } from "react-redux";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const TimeSheets = () => {
  const { fetchData } = useAxios();
  const navigate = useNavigate();

  const statusObj = {
    projectID: "",
    activityID: "",
    hours: "",
    status: "",
    description: "",
  };

  const [statusPayload, setStatusPayload] = useState({
    date: dayjs(new Date()),
    dailystatusID: "",
    List: [{ ...statusObj }],
  });
  const [isStatusModel, setIsStatusModel] = useState(false);
  const [value, setValue] = useState(dayjs().format("YYYY-MM-DD"));
  const [dailyWorkReport, setDailyWorkReport] = useState([]);
  const [Dates, setDates] = useState([]);
  const [maxProjectCount, setMaxProjectsCount] = useState(0);
  const [assignedProjectList, setAssignedProjectList] = useState([]);
  const [activitiesList, setActivitiesList] = useState([]);
  const [isAddNewProject, setIsAddNewProject] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [selectedReportIndexValue, setSelectedReportIndexValue] = useState(null);
  const [existingReport , setExistingReport]=useState(null);
  // const [user , setUser]=useState(null)

  const user=useSelector((state)=>state.WRMSAppSlice.user)
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    ModifytheStartDateAndGetReports(value);
    getAssignedProjectsList();
    getActivitiesList();
  }, [value]);

//   useEffect(()=>{
//     getUserProfle()
//   },[])

//   const getUserProfle = async () => {
//     const resp = await fetchData({ url: URLS.user, method: 'get' });
//     if (resp.status === 200) {
//         setUser(resp.data)
//     } else {
//         navigate("/")
//     }
// }


  const getActivitiesList = async () => {
    try {
      const resp = await fetchData({ url: URLS.getActivities, method: "get" });
      if (resp.status === 200) {
        setActivitiesList(resp.data.sort((a, b) => a.label.localeCompare(b.label)));

      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAssignedProjectsList = async () => {
    try {
      const resp = await fetchData({
        url: URLS.getAssignedProjects,
        method: "get",
      });
      if (resp.status === 200) {
        setAssignedProjectList(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ModifytheStartDateAndGetReports = async (value) => {
    let day = moment(value).format("ddd");

    let daysToCut = 0;

    switch (day) {
      case "Mon":
        daysToCut = 0;
        break;
      case "Tue":
        daysToCut = 1;
        break;
      case "Wed":
        daysToCut = 2;
        break;
      case "Thu":
        daysToCut = 3;
        break;
      case "Fri":
        daysToCut = 4;
        break;
      case "Sat":
        daysToCut = 5;
        break;
      case "Sun":
        daysToCut = 6;
        break;
      default:
        daysToCut = 0;
    }

    let modifiedStartDate = new Date(value);

    modifiedStartDate.setDate(modifiedStartDate.getDate() - daysToCut);

    let modifiedEndDate = new Date(modifiedStartDate);
    modifiedEndDate.setDate(modifiedStartDate.getDate() + 6);

    let datess = await Promise.all(
      Array.from({ length: 7 }).map((e, i) => {
        let nextdate = new Date(modifiedStartDate);

        nextdate.setDate(nextdate.getDate() + i);

        return nextdate;
      })
    );

    datess = datess.sort((a, b) => new Date(a) - new Date(b));
    setDates(datess);
    //await getTimeSheetInfo(modifiedStartDate, modifiedEndDate, datess);
  };



useEffect(()=>{
 getExistingWeekReport() 
},[Dates])

  const getExistingWeekReport = async()=>{
    try{
    if(Dates && Dates.length){
    
      let startDate=moment(new Date(Dates[0])).format("DD-MM-YYYY")
      let endDate=moment(new Date(Dates[Dates.length-1])).format("DD-MM-YYYY")
    
       const response = await fetchData({
         url: URLS.getTimeSheetData(startDate, endDate),
         method: "get",
     });

  //  console.log(response , 'resssssss')
   setExistingReport(response?.data)
   if(!response){
   setMaxProjectsCount(1);
   setExistingReport(null)
    await getTimeSheetInfo(new Date(Dates[0]), new Date(Dates[Dates.length-1]), Dates);
    return 
   }
     let maxProjects =response?.data?.report?.map(
      (each) => Object.values(each?.report).length
     );
    maxProjects = Math.max(...maxProjects) > 0 ? Math.max(...maxProjects) : 1;
    setMaxProjectsCount(maxProjects)
    setDailyWorkReport(response?.data?.report)
    
    }
     
    }
    catch(error){
      console.log(error , 'error')
    }
  }


  const getDateWiseReports = async (data = [], datess = []) => {
    let weekReport = [];

    let filteredData = data?.filter((eachData) => (eachData?.activityID && eachData?.projectID && eachData?.hours)) || []
    await Promise.all(
      datess?.map(async (eachDate) => {
        const dayReportData = filteredData?.find((eachData) => moment(new Date(eachDate)).format("YYYY-MM-DD") === moment(eachData?.dateview).format("YYYY-MM-DD"))
        if (!dayReportData) {
          filteredData.push({
            projectID: "",
            activityID: "",
            hours: "",
            status: "",
            description: "",
            dateview: moment(new Date(eachDate)).format("YYYY-MM-DD"),
            date: new Date(eachDate)
          })
        }
      })
    )

    filteredData?.forEach((each) => {
      let existingDateReportObj = weekReport.find(
        (obj) => each.dateview === obj.date
      );
      // console.log(existingDateReportObj , each.dateview ,'dateView')

      if (!existingDateReportObj) {
        weekReport.push({ date: each.dateview, report: [each] });
      } else {
        let indexOfExistingDateReportObj = weekReport?.findIndex(
          (obj) => each.dateview === obj.date
        );
        let existingReports = existingDateReportObj.report;
        weekReport[indexOfExistingDateReportObj] = {
          ...existingDateReportObj,
          report: [...existingReports, each],
        };
      }
    });

    // console.log(weekReport ,'weekReport')
    let maxProjects = weekReport.map(
      (each) => Object.values(each.report).length
    );
    maxProjects = Math.max(...maxProjects) > 0 ? Math.max(...maxProjects) : 1;
    const updatedWeekReport = weekReport?.map((eachReport) => {
      if (eachReport?.report?.length === maxProjects) {
        return eachReport
      } else {
        let updatedReports = []
        Array.from({ length: maxProjects - eachReport?.report?.length }).forEach(
          (def, index) => {
            updatedReports.push({
              projectID: "",
              activityID: "",
              hours: "",
              status: "",
              description: "",
              dateview: eachReport?.date,
              date: new Date(eachReport?.date)
            })
          })
        return { ...eachReport, report: [...eachReport?.report, ...updatedReports] }
      }
    })
    setMaxProjectsCount(maxProjects);
    // return weekReport;
    //setDailyWorkReport(updatedWeekReport);

    //console.log(updatedWeekReport, 'updatedWeekReport')
    return updatedWeekReport
  };

  const getTimeSheetInfo = async (start, end, datess) => {
    const startDate = moment(start).format("YYYY-MM-DD");
    const endDate = moment(end).format("YYYY-MM-DD");
    try {
      const response = await fetchData({
        url: URLS.getDashboardCalendarData(startDate, endDate),
        method: "get",
      });

      let DateWiseInfo = await getDateWiseReports(
        response?.data?.dailyWorkReports, datess
      );

      setDailyWorkReport([...DateWiseInfo]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDateChange = (newValue) => {
    let selectedDate = dayjs(newValue).format("YYYY-MM-DD");
    setValue(selectedDate);
  };

  const getReportsOfTheDay = (date) => {
    try {
      let reportfortheday = dailyWorkReport.filter((each) => {
        return (
          moment(new Date(each.date)).format("YYYY-MM-DD") ==
          moment(date).format("YYYY-MM-DD")
        );
      });

      //console.log( date ,reportfortheday[0].report || [] , 'rep')
      if (!reportfortheday.length) {
        return [{ description: "" }];
      }
      return reportfortheday[0].report ?? [];
    } catch (error) { }
  };

  const getProjectName = (projectID) => {
    let projectInfo = assignedProjectList?.find(
      (each) => each.projectId == projectID
    );
    return projectInfo?.projectName || "";
  };

  const getProjActivity = (activityID) => {
    let ActivityInfo = activitiesList?.find((each) => each.value == activityID);
    return ActivityInfo?._doc?.activityName || "";
  };

  const getDefaultValue = (date, i) => {
    // console.log(date, i, " date, i date, i")
    let reportfortheday = dailyWorkReport?.find((ech) => {
      return (
        moment(new Date(ech.date)).format("DD MM YYYY") ==
        moment(new Date(date)).format("DD MM YYYY")
      );
    });


    return reportfortheday?.report?.[i] || {};
  };

  const converHoursFormat = (value) => {
    if (parseInt(value) < 9) {
      if (value?.length > 1) {
        return `${value}:00`;
      }
      return `0${value}:00`;
    } else {
      return `${value}:00`;
    }
  };

  const modelHandle = () => {
    setIsStatusModel(!isStatusModel);
    setIsAddNewProject(false)
    setStatusPayload({
      date: dayjs(new Date()),
      dailystatusID: "",
      List: [{ ...statusObj }],
    })
    setSelectedReportIndexValue(null)
  };

  const handleEditTimeSheetClick = ({ data, selectedReportIndex = null }) => {
    try {
      // if(existingReport) return 
      setIsStatusModel(true);
      setStatusPayload((prev) => ({
        ...prev,
        date: data?.dateview,
        dailystatusID: data.dailystatusID || "",
        List: [{ ...data }],
      }));
      setSelectedReportIndexValue(selectedReportIndex)
    } catch (error) {
      console.log(error);
    }
  };

  const handleTimeSheetEdit = (data) => {
    try {
      let OuterIndex = dailyWorkReport.findIndex((each) => {
        return data?.date === each.date;
      });

      if (OuterIndex === -1) {
        return;
      }
      let triggeredOuterObj = dailyWorkReport[OuterIndex];
      let innerIndex = selectedReportIndexValue
      if (selectedReportIndexValue === null) {
        innerIndex = triggeredOuterObj?.report.findIndex((rep) => {
        return data?.List[0]?._id === rep._id;
      });
      }
      if (innerIndex === -1) {
        return;
      }

      let updatedTriggeredOuterObj = {
        ...triggeredOuterObj,
        report: triggeredOuterObj.report.map((item, index) =>
          index === innerIndex ? { ...item, ...data.List[0] } : item
        ),
      };

      let updatedDailyWorkReport = [...dailyWorkReport];
      updatedDailyWorkReport[OuterIndex] = updatedTriggeredOuterObj;

      setDailyWorkReport([...updatedDailyWorkReport]);
    } catch (error) {
      console.log(error, "error");
    }
  };


  // const handelNewTimeSheetClick = (date) => {
  //   try {
  //     setIsStatusModel(!isStatusModel);
  //     // setIsAddNewTimeSheet(true);
  //     setStatusPayload({
  //       date: dayjs(new Date(date)),
  //       dailystatusID: "",
  //       List: [{ ...statusObj }],
  //     })
  //   }
  //   catch (error) {

  //   }
  // }
  
  const handleSaveNewTimeSheet = (data) => {
    //console.log(dailyWorkReport , 'dailywork report')
    try {

      // check if date exist or not 
      //if date exist then push to report array 
      //if no date then create one Obj and push it to dailywork report 

      let DateValue = moment(new Date(data?.date['$d'])).format('YYYY-MM-DD')

      


      let isDateExist = dailyWorkReport.find((each) => {

        return each.date == DateValue
      })

      if (isDateExist) {
        //find the index of this obj
        //and for that push this new report data to report array 
        //update the state 
        
        let indexOfTheObj = dailyWorkReport.findIndex((each) => {
          return each.date == DateValue
        })

        let targettedDateObj = dailyWorkReport[indexOfTheObj];
        console.log(targettedDateObj , 'target')
        targettedDateObj.report.push(data?.List[0])


        let updatedDailyWorkReport = [...dailyWorkReport];

        updatedDailyWorkReport[indexOfTheObj] = targettedDateObj

        setDailyWorkReport([...updatedDailyWorkReport])

      }
      else {
if(DateValue !== 'Invalid date'){

        let newDateTimeSheet = { date: DateValue, report: [data?.List[0]] }
        setDailyWorkReport([...dailyWorkReport, newDateTimeSheet])
}

      }

    }
    catch (error) {
      console.log(error, 'error')
    }
  }



  // const handleTimeSheetSubmit = () => {
  //   try {
  //     const timeSheetPaylaodData = {
  //       startDate: moment(new Date(Dates[0] || '')).format("DD-MM-YYYY"),
  //       endDate: moment(new Date(Dates[Dates?.length - 1] || '')).format("DD-MM-YYYY"),
  //       timeSheetData: [...dailyWorkReport]
  //     }
  //     console.log(timeSheetPaylaodData, 'timeSheetPaylaodData')
  //   }
  //   catch (error) {

  //   }
  // }


  const handleTimeSheetSubmit = async() => {
    try {
     // console.log(dailyWorkReport, 'dailyworkreport');
      let bodyObj={
        startDate:moment(new Date(Dates[0])).format("DD-MM-YYYY"),
        endDate:moment(new Date(Dates[Dates.length-1])).format("DD-MM-YYYY"),
        report:dailyWorkReport
      }
    let res = await fetchData({ url: URLS.createTimesheet, method: "post" , body:bodyObj });
    
    getExistingWeekReport()
    }
    catch (error) {

    }
  }

  const getUpdateDailyReportData = (index) => {
    // console.log(index, "index index")
    let updatedDailyWorkReport = dailyWorkReport?.map((eachDay) => {
      return {
        ...eachDay, report: [...eachDay?.report?.slice(0, index), ...eachDay?.report?.slice(index + 1, eachDay?.report?.length)]
      }
    });
    // updatedDailyWorkReport[OuterIndex] = updatedTriggeredOuterObj;
    setMaxProjectsCount(prev => prev - 1)
    setDailyWorkReport([...updatedDailyWorkReport]);

  }

  const handleAddMaxProjectsCount = () => {
    setIsAddNewProject(true)
    setIsStatusModel(true);

  }
  const handleSaveNewProject = (data) => {
    // console.log(data, 'handleSaveNewProject report')
    // console.log(dailyWorkReport?.length, 'dailyWorkReport length')
    const SelectedProjectData = data?.List[0]
    // try {dateview
    if (dailyWorkReport?.length > 4) {
      let isModifiedDailyWorkReport = dailyWorkReport?.map((each) => {

        return {
          ...each,
          report: [...each?.report, { ...SelectedProjectData, dateview: each?.date }]
        }
      })
      // console.log(isModifiedDailyWorkReport, "isModifiedDailyWorkReport isModifiedDailyWorkReport")

      setDailyWorkReport(isModifiedDailyWorkReport)
      setMaxProjectsCount((prev) => prev + 1)
      modelHandle()
      console.log(isModifiedDailyWorkReport, 'isModifiedDailyWorkReport')

    } else {
      let WeeklyREport = []
      Dates?.forEach((date) => {
        WeeklyREport.push({
          date: moment(new Date(date)).format("YYYY-MM-DD"),
          report: [{ ...SelectedProjectData, dateview: moment(new Date(date)).format("YYYY-MM-DD"), date: new Date(date) }]
        })
      })
      console.log(WeeklyREport, "WeeklyREport WeeklyREport")
      setDailyWorkReport(WeeklyREport)
      setMaxProjectsCount((prev) => prev + 1)
      modelHandle()
    }
    // }
    // catch (error) {
    //   console.log(error, 'error')
    // }
  }


const getStatusInfo =(report)=>{
  try{
let data =report?.ManagerApprovals

return data?.map((each)=>{


switch (each.Status) {

  case  'Pending' :
        return  <Typography sx={{fontSize:"10px"}}>Pending With - {each.Name}</Typography>   
  case 'Approved':
        return <Typography sx={{fontSize:"10px"}}>Approved By - {each.Name}</Typography>
  
  case 'Rejected':
        return <Typography sx={{fontSize:"10px"}}>Rejected By -{each.Name}</Typography>
    
    default: return null
}

})


  }catch(error){

  }
}

const getComments =(report)=>{
  try{
    let data =report?.ManagerApprovals
    return data?.map((each)=>{
      return  each["comment"] && <Typography sx={{fontSize:"10px"}}> <b>{each.Name}</b>-{each.comment} </Typography>
    })
  }
  catch(error){

  }
}





  

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="title mb-3">My TimeSheet</h4>
        <div>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="TimeSheet" {...a11yProps(0)} />
            <Tab label="TimeSheet List" {...a11yProps(1)} />
          {user?.roles?.isTeam && <Tab label="Pending Approvals" {...a11yProps(1)} />}
          </Tabs>
        </div>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label={"Select Date"}
          defaultValue={dayjs()}
          inputFormat="DD/MM/YYYY"
          value={dayjs(value)}
          onChange={(newValue) => handleDateChange(newValue)}
          renderInput={(params) => <TextField {...params} />}
          maxDate={dayjs()}
        />
      </LocalizationProvider>
      <CustomTabPanel value={tabValue} index={0} className='px-0'>
       {existingReport ? <Box sx={{display:"flex" , gap:3, alignItems:"center"}}>
        <Typography>{moment(new Date(Dates[0])).format("DD-MM-YYYY")} to {moment(new Date(Dates[Dates.length-1])).format("DD-MM-YYYY")} </Typography>
        <Tooltip title={getStatusInfo(existingReport)}>
        <Button className={ existingReport?.status=='Approved'?'ts-approved-btn'  :   "ts-sts-btn" } >{existingReport?.status}</Button>
        </Tooltip>
        
        <Tooltip title={getComments(existingReport)}>
        <Box sx={{display:"flex" , gap:0.5, alignItems:"center"}}>
          <Image src={commtImage} style={{height:24 , width:24}} />
          <Typography sx={{fontSize: 12, fontWeight:'500'}}>
          Comments
          </Typography>
        

        </Box>
          </Tooltip>
       
        </Box>
        :<Box >
          <Typography>*This data is populated from your <b>daily work report.</b> You can edit or submit it.</Typography>
          </Box>}
        <Box className="card mt-4 timesheet-card">
          <Box className="timesheet-container">
            <Box className="date-map-cls">
              {Dates?.length &&
                Dates?.map((date) => {
                  return (
                    <Box className="show-dates" key={`${new Date(date)}`}>
                      <Typography>
                        {moment(new Date(date)).format("ddd")}
                      </Typography>
                      <Typography>
                        {moment(new Date(date)).format("DD-MM-YYYY")}
                      </Typography>
                    </Box>
                  );
                })}
              {/* <Box className="show-dates"> */}
              {!existingReport &&<Typography>
                Actions
              </Typography>}
              {/* </Box> */}
            </Box>
            {/* <Box className="card" sx={{ width: "1660px" }}> */}
            <Box className="reports-container " >
              {Dates.length && <>
                {Dates.map((date, i) => {
                  return (
                    <Box className="day-projects-map" key={`${new Date(date)}-${i}`}>
                      {maxProjectCount
                        ? Array.from({ length: maxProjectCount }).map(
                          (def, index) => {
                            return (
                              <>
                                {(getDefaultValue(date, index).projectID ||
                                  getDefaultValue(date, index).activityID ||
                                  getDefaultValue(date, index).description) ? (
                                  <Box
                                    sx={{ height: 110 }}
                                    className={`${moment(new Date(date)).format("ddd") ==
                                      "Sun" ||
                                      moment(new Date(date)).format("ddd") ===
                                      "Sat"
                                      ? "BackgroundClr"
                                      : ""
                                      } report-card`}
                                  >
                                    <Box className="d-flex justify-content-between">
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontFamily:'"Jost", sans-serif',
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {assignedProjectList?.length > 0 &&
                                          getProjectName(
                                            getDefaultValue(date, index)
                                              ?.projectID
                                          )}
                                      </Typography>
                                      <Typography className="time-hours">
                                        {converHoursFormat(
                                          getDefaultValue(date, index).hours
                                        )}
                                      </Typography>
                                    </Box>
                                    <Tooltip title={getDefaultValue(date, index)
                                      ?.description || ""}>
                                      <TextField
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            padding: 0,
                                            margin: 0,
                                            "& fieldset": {
                                              border: "none",
                                            },
                                            "&:hover fieldset": {
                                              border: "none",
                                            },
                                            "&.Mui-focused fieldset": {
                                              border: "none",
                                            },
                                            "& input": {
                                              padding: 0,
                                            },
                                          },
                                          "& .MuiInputBase-input": {
                                            padding: 0,
                                          },
                                          margin: 0,
                                          width: "100%",
                                        }}
                                        defaultValue={
                                          getDefaultValue(date, index)
                                            ?.description || ""
                                        }
                                        value={
                                          getDefaultValue(date, index)
                                            ?.description || ""
                                        }
                                        multiline
                                        inputProps={{
                                          style: { height: "50px", fontSize: 12, fontWeight: 500 },
                                          className: "day-work-status",
                                        }}
                                      />
                                    </Tooltip>
                                    <Box className="d-flex justify-content-between">
                                      {getDefaultValue(date, index)
                                        ?.activityID ? (
                                        <Box className="activity-Box">
                                          <Typography className="activity-typo">
                                            {activitiesList?.length > 0 &&
                                              getProjActivity(
                                                getDefaultValue(date, index)
                                                  ?.activityID
                                              )}
                                          </Typography>
                                        </Box>
                                      )
                                        : <Box ></Box>
                                      }
                                      {(getDefaultValue(date, index) && (!existingReport ||(existingReport && ['Pending', 'Rejected'].includes(existingReport?.status) ) )) && (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          onClick={() =>
                                            handleEditTimeSheetClick({
                                              data: getDefaultValue(
                                                date,
                                                index
                                              ),
                                              selectedReportIndex: index
                                            })
                                          }
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <path
                                            d="M12.5007 5.00007L15.0007 7.50007M10.834 16.6667H17.5007M4.16732 13.3334L3.33398 16.6667L6.66732 15.8334L16.3223 6.17841C16.6348 5.86586 16.8103 5.44201 16.8103 5.00007C16.8103 4.55813 16.6348 4.13429 16.3223 3.82174L16.179 3.67841C15.8664 3.36596 15.4426 3.19043 15.0007 3.19043C14.5587 3.19043 14.1349 3.36596 13.8223 3.67841L4.16732 13.3334Z"
                                            stroke="#A1A1A1"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      )}
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box
                                    // onClick={() => handelNewTimeSheetClick(date)}
                                    onClick={() =>
                                      handleEditTimeSheetClick({
                                        data: getDefaultValue(
                                          date,
                                          index
                                        ),
                                        selectedReportIndex: index
                                      })
                                    }
                                    sx={{ height: 110 }}
                                    className={`${moment(new Date(date)).format("ddd") ==
                                      "Sun" ||
                                      moment(new Date(date)).format("ddd") ===
                                      "Sat"
                                      ? "empty-BackgroundClr"
                                      : "empty-card"
                                      } report-card`}
                                  >
                                    {/* {!["Sun", "Sat"].includes(
                                      moment(new Date(date)).format("ddd")
                                    ) && ( */}
                                   {!existingReport&&<Typography className="add-timesheet"  >
                                      + Add Timesheet
                                    </Typography>}
                                    {/* )} */}
                                  </Box>
                                )}
                              </>
                            );
                          }
                        )
                        : null}
                    </Box>
                  );
                })}
                <Box className="d-flex flex-column justify-content-around align-items-center">
                  {maxProjectCount && !existingReport ?
                    Array.from({ length: maxProjectCount }).map(
                      (def, index) => {
                        return (
                          <Box key={`maxProjectCount-${index}-index`}>
                            {index !== 0 ?
                              <Tooltip title='delete'><IconButton onClick={() => {
                                getUpdateDailyReportData(index)
                              }}><DeleteIcon /></IconButton></Tooltip> : null}
                          </Box>

                        )
                      }
                    ) : null}
                </Box>
              </>}
            </Box>
            {/* </Box> */}
          </Box>
          {(!existingReport ||(existingReport && ['Pending', 'Rejected'].includes(existingReport?.status) ) ) && <Box className="addnew-cls">
            <Box>
              <Typography
                onClick={() => {
                  handleAddMaxProjectsCount()
                  // setMaxProjectsCount((prev) => prev + 1)
                }
                }
                className="add-new-typo"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M10 2.375C5.52 2.375 1.875 6.02 1.875 10.5C1.875 14.98 5.52 18.625 10 18.625C14.48 18.625 18.125 14.98 18.125 10.5C18.125 6.02 14.48 2.375 10 2.375ZM10 3.625C13.8044 3.625 16.875 6.69563 16.875 10.5C16.875 14.3044 13.8044 17.375 10 17.375C6.19563 17.375 3.125 14.3044 3.125 10.5C3.125 6.69563 6.19563 3.625 10 3.625ZM9.375 6.75V9.875H6.25V11.125H9.375V14.25H10.625V11.125H13.75V9.875H10.625V6.75H9.375Z"
                    fill="#2C3E50"
                  />
                </svg>{" "}
                Add New
              </Typography>
            </Box>
            <Box className="submit-btn">
              <Button className="sbtn" size="small" disabled={!(!existingReport ||(existingReport && ['Pending', 'Rejected'].includes(existingReport?.status) ) )} onClick={() => handleTimeSheetSubmit()}>
                Submit
              </Button>
            </Box>
          </Box> 
          }
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <div className="d-flex justify-content-center align-items-center mt-3" style={{ height: '300px' }}>  <h4>Currently Under Development!</h4></div>
      </CustomTabPanel>
      {user?.roles?.isTeam &&
      <CustomTabPanel value={tabValue} index={2}>
      <TeamTimeSheets userInfo={user} />
      </CustomTabPanel>
}
      {isStatusModel && (
        <AddStatus
          isStatusModel={isStatusModel}
          statusPayload={statusPayload}
          modelHandle={modelHandle}
          setStatusPayload={setStatusPayload}
          statusObj={statusObj}
          assignedProjectList={assignedProjectList}
          activitiesList={activitiesList}
          disabledDate={true}
          handleTimeSheetEdit={handleTimeSheetEdit}
          showAddRemoveBtns={false}
          isAddNewTimeSheet
          handleSaveNewTimeSheet={handleSaveNewTimeSheet}
          isAddNewProject={isAddNewProject}
          handleSaveNewProject={handleSaveNewProject}
        />
      )}
    </div>
  );
};

export default TimeSheets;
