import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { lightBlue } from "@mui/material/colors";
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import NavDropdown from "react-bootstrap/NavDropdown";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import NotificationPanel from "../Notifications/Notification-Panel";
import "./AdminHeader.scss";
import { useDispatch } from "react-redux";
import { setLoggedinUser } from "../../Redux/reducer";

const AdminHeader = ({ setToggle, toggle, user }) => {

  const { fetchData } = useAxios();
const dispatch=useDispatch()
  const navigate = useNavigate();

  const x = Array.from(user.employee.employeeName)[0];

  const [openNotitficationPanel, setOpenNotificationPanel] = useState(false);

  const [data, setData] = useState("")

  const getValue = (person) => {
    setData(person)
  }

  return (
    <div className="w-100">
      <div className="container-fluid p-0">
        <div className="bg-white shadow-sm d-flex justify-content-between ps-3 pe-3 pt-3 pb-3">
          <div className="d-flex align-items-center btn border-0 p-0 m-0">
            <FormatAlignLeftIcon
              className={`me-3 ${!toggle ? 'sidebar-toggle-closed-btn' : 'sidebar-toggle-open-btn'}`}
              onClick={() => setToggle(!toggle)}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3 big_dropdown">
              <div className="pull-left">
                <MailOutlineIcon className="icons-header" />
              </div>
            </div>
            <div className="me-3 big_dropdown">
              <div className="pull-left" onClick={() => { setOpenNotificationPanel(true) }}>
                <Badge
                  color="info"
                  badgeContent={data}
                >
                  <NotificationsNoneIcon
                    className="icons-header"
                  />
                </Badge>
              </div>
            </div>
            <div className="big_dropdown">
              <NavDropdown
                eventkey={1}
                title={
                  <div className="pull-left">
                    <Avatar
                      sx={{ width: 31, height: 31, bgcolor: lightBlue[900] }}
                    >
                      {x}
                    </Avatar>
                  </div>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  eventkey={1.1}
                  disabled
                >
                  <span className="text-dark" style={{ cursor: "default" }}>
                    {" "}
                    {user.employee.employeeName}{" "}
                  </span>
                </NavDropdown.Item>
                <NavDropdown.Item eventkey={1.1} onClick={() => { navigate('/profile') }}>
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventkey={1.3}
                  onClick={async (e) => {
                    e.preventDefault();
                    try {
                      const resp = await fetchData({
                        url: URLS.logout,
                        method: "get",
                      });
                      if (resp.status === 200) {
                        dispatch(setLoggedinUser({}))
                        navigate("/login");
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
            <div className="small_dropdown">
              <NavDropdown
                eventkey={1}
                title={
                  <div className="pull-left">
                    <Avatar
                      sx={{ width: 31, height: 31, bgcolor: lightBlue[900] }}
                    >
                      {x}
                    </Avatar>
                  </div>
                }
                id="basic-nav-dropdown">
                <NavDropdown.Item eventkey={1.1} disabled>
                  <span className="text-dark" style={{ cursor: "default" }}>
                    {" "}
                    {user.employee.employeeName}{" "}
                  </span>
                </NavDropdown.Item>
                <NavDropdown.Item eventkey={1.1} href="/profile">
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventkey={1.3}
                  onClick={async (e) => {
                    e.preventDefault();
                    try {
                      const resp = await fetchData({ url: URLS.logout, method: "get" });
                      if (resp.status === 200) {
                        navigate("/");
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
      <NotificationPanel getValue={getValue} openNotitficationPanel={openNotitficationPanel} setOpenNotificationPanel={setOpenNotificationPanel} />
    </div>
  )
}

export default AdminHeader