import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import './AttendanceCalendar.scss'
import useAxios from '../../helpers/Hooks/useAxios';
import { URLS } from '../../helpers/URLS';
import moment from 'moment';
import CircleIcon from '@mui/icons-material/Circle';
import { Grid, Typography, Box } from '@mui/material';


const AttendanceCalendar = (props) => {
    const { onSelectDate } = props;
    const { fetchData } = useAxios();

    const [calendarData, setCalendarData] = useState({});
    const [events, setEvents] = useState([]);


    const getCalendarData = async (start, end) => {
        const startDate = moment(start).format('YYYY-MM-DD');
        const endDate = moment(end).format('YYYY-MM-DD');
        const response = await fetchData({
            url: URLS.getDashboardCalendarData(startDate, endDate),
            method: "get",
        });
        if (response.status === 200) {
            setCalendarData(response.data);
            generateEvents(response.data, startDate, endDate)
        }
    }

    const generateEvents = (data, startDate, endDate) => {
        let reportingDates = [];
        for (var m = moment(startDate); m.isSameOrBefore(new Date()); m.add(1, 'd')) {
            const weekName = moment(m).format("d");
            const currentDate = m.format("l");
            let isDateOnLeave = data.leaves.filter(leave => {
                let isBetween = moment(currentDate).isBetween(moment(leave.startDate), moment(leave.endDate).add(1, 'd'));
                let isSame = moment(currentDate).isSame(moment(leave.startDate), moment(leave.endDate).add(1, 'd'));
                if (isBetween || isSame) {
                    return true
                }
                return false;
            })

            if (![0, 6].includes(Number(weekName)) && isDateOnLeave.length === 0) {
                reportingDates.push(m.format('YYYY-MM-DD'));
            }
        }

        const workReportEvents = reportingDates.map(rdate => {
           
            const reportExist = data.dailyWorkReports.findIndex(report => moment(report.date).format('YYYY-MM-DD') === rdate);
            if(data.dailyWorkReports[reportExist]?.activityID === 'MLPA10'){
                return {
                    type: 'Holiday',
                    start: rdate,
                    end: rdate,
                    display: 'background',
                    backgroundColor: '#64abe8'
                }

            } else {
            return {
                type: 'working',
                start: rdate,
                end: rdate,
                display: 'background',
                backgroundColor: reportExist === -1 ? "#e53935" : '#29b229'
            }
        }
        })

        const leaveEvents = data.leaves.map(leave => {
            return {
                type: 'leave',
                start: moment(leave.startDate).format('YYYY-MM-DD'),
                end: moment(leave.endDate).add(1, 'd').format('YYYY-MM-DD'),
                display: 'background',
                backgroundColor: '#ffcc65'
            }
        })

        const weekends = []
        for (var m = moment(startDate); m.isSameOrBefore(new Date(endDate)); m.add(1, 'd')) {
            const weekName = moment(m).format("d");
            if ([0, 6].includes(Number(weekName))) {
                weekends.push(m.format('YYYY-MM-DD'));
            }
        }
        const disabledWeekEnds = weekends.map(weekend => {
            return {
                type: 'weekend',
                start: moment(weekend).format('YYYY-MM-DD'),
                end: moment(weekend).format('YYYY-MM-DD'),
                display: 'background',
                backgroundColor: '#e7e7e7'
            }
        })

        setEvents([...workReportEvents, ...leaveEvents, ...disabledWeekEnds])
    }


    const handleDateClick = (arg) => {
        let isDateOnLeave = calendarData?.leaves.filter(leave => {
            let isBetween = moment(arg.date).isBetween(moment(leave.startDate), moment(leave.endDate).add(1, 'd'));
            let isSame = moment(arg.date).isSame(moment(leave.startDate), moment(leave.endDate).add(1, 'd'));
            if (isBetween || isSame) {
                return true
            }
            return false;
        })
        if (isDateOnLeave.length === 0 && moment(arg.date).isSameOrBefore(new Date())) {
            onSelectDate(moment(arg.date).format('YYYY-MM-DD'))
        }
    }



    return (
        <div className='calendar-block'>
            <FullCalendar plugins={[dayGridPlugin, interactionPlugin]}
                initialView='dayGridMonth'
                dateClick={handleDateClick}
                // selectable
                firstDay={0}
                aspectRatio={3.2}
                headerToolbar={{
                    start: 'prev',
                    center: 'title',
                    end: 'next'
                }}
                events={events}
                rerenderDelay={true}
                datesSet={(dateInfo) => {
                    getCalendarData(dateInfo.start, dateInfo.end)
                }}
            />

            <Box p={2}>
                <Grid container>
                    <Grid item md={3}>
                        <Typography >
                            <CircleIcon sx={{ color: '#29b229' }} /> Report submitted
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography >
                            <CircleIcon sx={{ color: '#e53935' }} /> Report not submitted
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography >
                            <CircleIcon sx={{ color: '#ffcc65' }} /> Leave
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography >
                            <CircleIcon sx={{ color: '#64abe8' }} /> Holiday
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

        </div>
    )
}

export default AttendanceCalendar;
