import React, { useState, useEffect } from "react";
import { URLS } from "../../helpers/URLS";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { subscribeUser } from '../../subscription';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import WysiwygOutlinedIcon from "@mui/icons-material/WysiwygOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import useAxios from "../../helpers/Hooks/useAxios";
import Barchart from "./Charts/Barchart";
import Piechart from "./Charts/Piechart";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import TextField from '@mui/material/TextField';
import PaginationGlobal from "../TablePagination.js/PaginationGlobal";
import moment from "moment";
import "./Dashboard.scss";
import AttendanceCalendar from "./AttendanceCalendar";
import AddStatus from "../DailyStatus/AddStatus";
import NotificationTab from '../../components/Notifications/NotificationTabs';

import dayjs from 'dayjs';
import DashboardNotifications from "./DashboardNotifications";
import Holidays from "./Holidays";
const statusObj = {
  projectID: "",
  activityID: "",
  hours: "",
  status: "",
  description: ""
}

const Dashboard = () => {
  const { fetchData } = useAxios();

  const [leavesStartDate, setLeavesStartDate] = useState(new moment())
  const [leavesEndDate, setLeavesEndDate] = useState(moment().add(7, 'days'))
  const [todayDateBarChart, setTodayDateBarChart] = useState(new Date());
  const [teamLeaves, setTeamLeaves] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isStatusModel, setIsStatusModel] = useState(false);
  const [assignedProjectList, setAssignedProjectList] = useState([]);
  const [activitiesList, setActivitiesList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [attendanceCalenderKey, setAttendanceCalenderKey] = useState(Math.random());

  const [statusPayload, setStatusPayload] = useState({
    date: dayjs(new Date()),
    dailystatusID: "",
    List: [{ ...statusObj }]
  })

  const [barchartData, setBarChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Team count",
        data: [],
        backgroundColor: "#67b7dc",
        barThickness: 30,
      },
      {
        label: "No of Absentees",
        data: [],
        backgroundColor: "#a367dc",
        barThickness: 30,
      },
    ],
  });

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "#67b7dc",
          "#a367dc",
          "#8067dc",
          "#6771dc",
          "#9BEC58",
          "#7EE6D5",
          "#71B6F2",
          "#EE905B",
        ],
      },
    ],
  });



  useEffect(() => {
    setTimeout(() => {
      subscribeUser()
    }, 1000);
  }, []);

  useEffect(() => {
    getDashBoardData(todayDateBarChart);
    getTeamLevelLeaves(leavesStartDate, leavesEndDate);

    getActivitiesList();
    getAssignedProjectsList();
  }, []);

  const getTeamLevelLeaves = async (leavesStartDate, leavesendDate) => {
    const response = await fetchData({
      url: `${URLS.getDashboardLeavesData}?beginDate=${leavesStartDate}&closeDate=${leavesendDate}&status=approved`,
      method: "get",
    });
    if (response.status === 200) {
      setTeamLeaves(response.data)
    }
  }

  const getDashBoardData = async (date) => {
    const response = await fetchData({
      url: `${URLS.getDashboardData}?date=${date}`,
      method: "get",
    });
    if (response.status === 200) {
      const chartLabels = [];
      const Chartlength = [];
      const absentees = [];
      response.data.forEach((item) => {
        chartLabels.push(item.project.projectName);
        Chartlength.push(item.employees.length);
        absentees.push(item.absentees.length);
      });
      setChartData({
        ...chartData,
        labels: chartLabels,
        datasets: [
          {
            data: Chartlength,
            backgroundColor: chartData.datasets[0].backgroundColor,
          },
        ],
      });
      setBarChartData({
        ...barchartData,
        labels: chartLabels,
        datasets: [
          {
            label: barchartData.datasets[0].label,
            data: Chartlength,
            backgroundColor: barchartData.datasets[0].backgroundColor,
          },
          {
            label: barchartData.datasets[1].label,
            data: absentees,
            backgroundColor: barchartData.datasets[1].backgroundColor,
          },
        ],
      });
    }
  };

  const barChartDateChange = (date) => {
    setTodayDateBarChart(date)
    getDashBoardData(date);
  }

  const leavesStartDateChange = (date) => {
    let updatedDate = moment(date.$d).format('YYYY-MM-DD');
    setLeavesStartDate(updatedDate)
    setLeavesEndDate(null)
  }

  const leavesEndDateChange = (date) => {
    let updatedDate = moment(date.$d).format('YYYY-MM-DD');
    setLeavesEndDate(updatedDate)
    getTeamLevelLeaves(leavesStartDate, updatedDate);
  }

  const getPageNo = (data) => {
    setPageNumber(data)
  }

  const countPerPage = 5;



  //======== Add status ===============
  const modelHandle = () => {
    setIsStatusModel(!isStatusModel)
  }

  const getActivitiesList = async () => {
    try {
      const resp = await fetchData({ url: URLS.getActivities, method: 'get' });
      if (resp.status === 200) {
        setActivitiesList(resp.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getAssignedProjectsList = async () => {
    try {
      const resp = await fetchData({ url: URLS.getAssignedProjects, method: 'get' });
      if (resp.status === 200) {
        setAssignedProjectList(resp.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onSelectDate = async (selectedDate) => {
    try {
      const resp = await fetchData({ url: `${URLS.getDailyStatus}?startDate=${selectedDate}&endDate=${selectedDate}`, method: 'get' });
      if (resp.status === 200 && resp.data.length) {
        setStatusList(resp.data);
        resp.data.forEach(row => {
          setStatusPayload({
            date: selectedDate,
            dailystatusID: row.data[0].dailystatusID,
            List: row.data.map(item => {
              return {
                projectID: item.projectID,
                activityID: item.activityID,
                hours: item.hours,
                status: item.status,
                description: item.description
              }
            })
          })
        })
      } else {
        setStatusPayload({
          date: selectedDate,
          dailystatusID: "",
          List: [{ ...statusObj }]
        })
      }
      setIsStatusModel(true);

    } catch (error) {
      console.log(error);
    }

  }


  return (
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
          <div className="card bg-white shadow-sm mb-3">
            <div className="card-body py-0">
              <div className="d-flex align-items-center">
                <div className="icons">
                  <AccountCircleOutlinedIcon />
                </div>
                <div className="pt-2 cardText">
                  <p className="card-title font-weight-light mb-2">
                    {" "}
                    Active Projects
                  </p>
                  <p className="fs-6">2</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
          <div className="card bg-white shadow-sm mb-3">
            <div className="card-body py-0">
              <div className="d-flex align-items-center">
                <div className="icons">
                  <GridViewOutlinedIcon />
                </div>
                <div className="pt-2 cardText">
                  <p
                    className="card-title font-weight-light mb-2"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Pending Work Reports
                  </p>
                  <p className="fs-6">20-10-2022</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
          <div className="card bg-white shadow-sm mb-3">
            <div className="card-body py-0">
              <div className="d-flex align-items-center">
                <div className="icons">
                  <WysiwygOutlinedIcon />
                </div>
                <div className="pt-2 cardText">
                  <p className="card-title font-weight-light mb-2">
                    Remaining Leaves
                  </p>
                  <p className="fs-6">3 Days</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
          <div className="card bg-white shadow-sm mb-3">
            <div className="card-body py-0">
              <div className="d-flex align-items-center">
                <div className="icons">
                  <WebhookOutlinedIcon />
                </div>
                <div className="pt-2 cardText">
                  <p className="card-title font-weight-light mb-2">
                    Hired Candidates
                  </p>
                  <p className="fs-6">7</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6 className="card-title mb-2"><b>Work Report Calendar</b></h6>
      <div className="row">
        <div className="col-12 col-xl-8">
          <div className="card mb-3 shadow-sm">
            <div className="card-body">
              <AttendanceCalendar key={attendanceCalenderKey} onSelectDate={onSelectDate} />
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-4">
          <div className="card bg-white shadow-sm mb-3 chart-card">
            <div className="card-body py-0">
              <h3 className="card-title py-2"><b>Notifications</b></h3>
              <DashboardNotifications />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-7">
          <div className="card bg-white shadow-sm mb-3 chart-card">
            <div className="card-body py-0">
              <div className="d-flex align-items-center py-2">
                <p className="me-auto mt-2"><b>Project Analysis</b></p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date"
                    inputFormat="YYYY-MM-DD"
                    value={todayDateBarChart}
                    onChange={barChartDateChange}
                    renderInput={(params) => <TextField {...params}
                      sx={{ width: 150 }} />}
                  />
                </LocalizationProvider>
              </div>
              <hr className="my-0"></hr>
              <Barchart chartData={barchartData} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="card bg-white shadow-sm mb-3 chart-card">
            <div className="card-body py-0">
              <div className="d-flex align-items-center">
                <p className="pt-3"><b>Project Team Analysis</b></p>
              </div>
              <hr className="my-2"></hr>
              <Piechart chartData={chartData} />
            </div>
          </div>
        </div>
      </div>
      <h6 className="card-title my-2"><b>Holidays, Weekly Offs</b></h6>
      <div className="row">
        <div className="col-md-5">
          <div className="card bg-white shadow-sm mb-3 ">
            <div className="card-body py-0">
              <Holidays />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="card bg-white shadow-sm mb-3 ">
            <div className="card-body py-0">
              <div className="d-flex align-items-center py-2">
                <p className=" me-auto"><b>Team Leaves</b></p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="YYYY-MM-DD"
                    value={leavesStartDate}
                    onChange={leavesStartDateChange}
                    renderInput={(params) => <TextField {...params}
                      sx={{ width: 150, marginRight: 3 }} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="End Date"
                    minDate={leavesStartDate}
                    inputFormat="YYYY-MM-DD"
                    value={leavesEndDate}
                    onChange={leavesEndDateChange}
                    renderInput={(params) => <TextField {...params}
                      sx={{ width: 150 }} />}
                  />
                </LocalizationProvider>
              </div>
              <hr className="my-0"></hr>
              {teamLeaves.length > 0 ?
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Employee Name</th>
                        <th scope="col">Leave Type</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teamLeaves && teamLeaves.slice((pageNumber - 1) * countPerPage,
                        pageNumber * countPerPage).map((eachItem, index) => {
                          return (
                            <tr key={index}>
                              <td>{eachItem.requestedBy.employeeName} </td>
                              <td>{eachItem.leaveType.leaveType}</td>
                              <td>{moment(eachItem.startDate).format('YYYY-MM-DD')}</td>
                              <td>{moment(eachItem.endDate).format('YYYY-MM-DD')}</td>
                              <td>{moment(eachItem.endDate).diff(moment(eachItem.startDate), 'days') + 1} days</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  {teamLeaves && teamLeaves.length > 0 ? <PaginationGlobal count={Math.ceil(teamLeaves.length / countPerPage)} pageNumber={pageNumber}
                    func={getPageNo} /> : ''
                  }
                </div> :
                <h5 className="text-center my-5"><SearchOffIcon sx={{ fontSize: 40 }} className="text-primary" /> No Team Member is on Leave</h5>
              }
            </div>
          </div>
        </div>

      </div>


      {/* Add Status  */}
      <AddStatus isStatusModel={isStatusModel} modelHandle={modelHandle} statusPayload={statusPayload}
        setStatusPayload={setStatusPayload} statusObj={statusObj} assignedProjectList={assignedProjectList}
        activitiesList={activitiesList} getDailyStatus={() => setAttendanceCalenderKey(Math.random())} disabledDate={true} />

    </div>
  )
}

export default Dashboard

