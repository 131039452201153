import React from 'react'
import footerLogo from "../../assets/images/footer-logo.png"
import "./Footer.scss";

const Footer = () => {
    return (
        <div>
            <section className='footer-section py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-5 col-md-5 col-lg-3'>
                            <img src={footerLogo} width={160} className="img-fluid mb-3" alt="office" />
                            <div className="">
                            Motivity Labs is an award-winning IT Services company that focuses on Cloud, Mobile, Big data, and Innovation.
                            </div>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-2 offset-md-2'>
                            <h5>Services</h5>
                            <a href="#" className="link-light d-block">Product Engineering</a>
                            <a href="#" className="link-light d-block">Quality Engineering</a>
                            <a href="#" className="link-light d-block">DevOps</a>
                            <a href="#" className="link-light d-block">Data Analytics</a>
                            <a href="#" className="link-light d-block">Digital Innovation</a>
                            <a href="#" className="link-light d-block">Agile Methodology</a>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-2'>
                        <h5>Resources</h5>
                            <a href="#" className="link-light d-block">Contact Us</a>
                            <a href="#" className="link-light d-block">FAQ's</a>
                            <a href="#" className="link-light d-block">Community</a>
                            <a href="#" className="link-light d-block">Privacy Policy</a>
                            <a href="#" className="link-light d-block">Contact Us</a>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-3'>
                        <h5>Address</h5>
                            <p>Dallas Center, 6th & 7th Floor, 83/1, No A1, Knowledge City Rd, Rai Durg, Hyderabad, Telangana 500032.</p>
                            <p>Email: hello@motivitylabs.com</p>
                            <p>Phone: + 91 40 4651 5454</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer