import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { TextField, Grid, Button, Tooltip, MenuItem } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { URLS } from "../../helpers/URLS";
import moment, { now } from "moment";
import useAxios from "../../helpers/Hooks/useAxios";

const AddStatus = ({ isStatusModel, modelHandle, statusPayload, setStatusPayload, statusObj, assignedProjectList, activitiesList, getDailyStatus,
    disabledDate = false, showAddRemoveBtns = true, handleTimeSheetEdit = () => { }, isAddNewTimeSheet = false, handleSaveNewTimeSheet = () => { }, isAddNewProject = false, handleSaveNewProject = () => { } }) => {

    //  console.log(activitiesList, 'activitiesList')
    useEffect(() => {
        handleTimeSheetEdit(statusPayload)
    }, [statusPayload])

    const paylaodData = statusPayload?.List[0]
    // console.log(statusPayload?.List[0], 'statusPayload?.List[0]')


    const { fetchData } = useAxios();
    return (
        <div>
            <Modal size="lg" show={isStatusModel} onHide={modelHandle} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='work-report-section bg-white'>
                        <div className="">
                            {!isAddNewProject && <><div className="d-flex justify-content-between align-items-center pe-3">
                                <div className="form-group">
                                    <Grid item xs={6} sx={{ padding: 2 }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={statusPayload.date}
                                                maxDate={new Date()}
                                                onChange={(date) => { setStatusPayload({ ...statusPayload, date: new Date(date) }) }}
                                                renderInput={(params) => <TextField {...params} fullWidth required />}
                                                disabled={disabledDate}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </div>
                                {showAddRemoveBtns && <div className="">
                                    <div className='text-end'>
                                        <Tooltip title="Add New Report">
                                            <button onClick={(e) => {
                                                const list = [...statusPayload.List];
                                                list.push({ ...statusObj });
                                                setStatusPayload({ ...statusPayload, List: list })
                                            }} className='btn btn-success rounded-circle'>+</button>
                                        </Tooltip>
                                    </div>
                                </div>}
                            </div>
                                <hr />
                            </>}
                            <div className="modal_scroll px-3">
                                {statusPayload.List.map((row, index) => <div key={index} className="border-bottom py-3">
                                    {showAddRemoveBtns && <div className='text-end'>
                                        <Tooltip title="Delete Status Report" placement="top" arrow>
                                            <button onClick={(e) => {
                                                const list = [...statusPayload.List];
                                                list.splice(index, 1);
                                                setStatusPayload({ ...statusPayload, List: list })
                                            }} className='btn btn-danger rounded-circle'>---</button>
                                        </Tooltip>
                                    </div>}
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <TextField sx={{ mt: 3 }} select label={'Project'} fullWidth size="small"
                                                    value={statusPayload.dailystatusID ? row.projectID : assignedProjectList.find(x => x.value === row.projectID)?.value}
                                                    onChange={(e) => {
                                                        const list = [...statusPayload.List];
                                                        list[index].projectID = e.target.value;
                                                        list[index].activityID = "";
                                                        setStatusPayload({ ...statusPayload, List: list })
                                                    }}
                                                >
                                                    {
                                                        assignedProjectList.map((item, index) =>
                                                            <MenuItem key={index} value={item.value} disabled={new Date(moment(item.projectEndDate).format('L')) >= new Date(moment(now()).format('L')) ? false : true}>{item.label}</MenuItem>
                                                        )
                                                    }
                                                </TextField>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <TextField sx={{ mt: 3 }} select label={'Activity'} fullWidth size="small"
                                                    value={statusPayload.dailystatusID ? row.activityID : activitiesList.find(x => x.value === row.activityID)?.value}
                                                    disabled={row.projectID === '' ? true : false}
                                                    onChange={((e) => {
                                                        const list = [...statusPayload.List];
                                                        list[index].activityID = e.target.value;
                                                        setStatusPayload({ ...statusPayload, List: list })
                                                    })}
                                                >
                                                    {
                                                        activitiesList.map((item, index) =>

                                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                        )
                                                    }
                                                </TextField>
                                            </div>
                                        </div>
                                        <div className="col-md-4 ">
                                            <div className="form-group">
                                                <TextField sx={{ mt: 3 }} id="outlined-basic" fullWidth label="Hours" variant="outlined"
                                                    value={row.hours}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} onChange={(obj) => {
                                                        const list = [...statusPayload.List];
                                                        list[index].hours = obj.target.value;
                                                        setStatusPayload({ ...statusPayload, List: list })
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="form-group">
                                                <TextField sx={{ mt: 3 }} id="outlined-basic" rows={2} multiline label="Description" variant="outlined" fullWidth
                                                    value={row.description}
                                                    onChange={(obj) => {
                                                        const list = [...statusPayload.List];
                                                        list[index].description = obj.target.value;
                                                        setStatusPayload({ ...statusPayload, List: list })
                                                    }}
                                                    // disabled={isAddNewProject}
                                                    className="day-report-status-desc"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {showAddRemoveBtns && <Modal.Footer>
                    <Button variant="contained" color='inherit' onClick={modelHandle} className="me-3">
                        Close
                    </Button>
                    <Button variant="contained"
                        onClick={async (e) => {
                            const payload = {
                                ...statusPayload,
                                date: moment(statusPayload.date).format('YYYY-MM-DD')
                            };
                            const resp = await fetchData({ url: URLS.manageDailyStatus, method: 'post', body: payload });
                            if (resp.status === 200) {
                                toast.success(resp.message)
                                modelHandle();
                                getDailyStatus();
                            }
                            else {
                                toast.error(resp.message)
                            }
                        }} >
                        {statusPayload.dailystatusID ? "Update" : "Save"}
                    </Button>
                </Modal.Footer>
                }
                {isAddNewTimeSheet && <Modal.Footer>
                    <Button variant="contained" onClick={() => {
                        if (isAddNewProject) {
                            handleSaveNewProject(statusPayload)
                        } else {
                            handleSaveNewTimeSheet(statusPayload)
                        }
                        modelHandle()
                    }}
                        disabled={(paylaodData?.projectID && paylaodData?.activityID && paylaodData?.hours) ? false : true}
                    >
                        Save
                    </Button>
                </Modal.Footer>
                }
            </Modal>
        </div>
    )
}

export default AddStatus
