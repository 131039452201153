import "./Login.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URLS } from "./helpers/URLS";
import useAxios from "./helpers/Hooks/useAxios";
import NabBar from "./components/Navbar/NabBar";
import Footer from "./components/Footer/Footer";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./graph";
import Loginpic from "./assets/images/login.png"

const Login = () => {

  const navigate = useNavigate();
  const { fetchData } = useAxios();
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then((response) => {
      callMsGraph(response.accessToken).then(response => setGraphData(response));
    });
  }


  const handleLogin = (e, loginType = "popup") => {
    e.preventDefault();
    // if (loginType === "popup") {
    //     instance.loginPopup(loginRequest).catch(e => {
    //         console.log(e);
    //     });
    // } else if (loginType === "redirect") {
    //     instance.loginRedirect(loginRequest).catch(e => {
    //         console.log(e);
    //     });
    // }
  }


  const [loginPayload, setLoginPayload] = useState({
    //email: "bhargavi.palli@motivitylabs.com",
    email: "",
    password: "",
  });

  useEffect(() => {
    checkIsAuthenticated();
  }, [])

  const checkIsAuthenticated = async () => {
    const resp = await fetchData({ url: URLS.auth, method: "get" });
    if (resp.status === 200) {
      navigate("/dashBoard");
    }
  }

  const handleChange = (e) => {
    setLoginPayload({
      ...loginPayload,
      [e.target.name]: e.target.value,
    });
  };

  const submitLogin = async (e) => {
    if (!e) {
      return null;
    }
    e.preventDefault();
    const resp = await fetchData({
      url: URLS.login,
      method: "post",
      body: loginPayload,
    });
    if (resp.status === 200) {
      navigate("/dashBoard");
      toast.success(resp.message)
    }
    else {
      toast.error(resp.message)
    }
  }

  return (
    <div>
      <NabBar />
      <form onSubmit={submitLogin}>
        <div id="login">
          <div className="container p-3">
            <div className="row d-flex justify-content-center p-5">
              <div className="profile col-6 col-lg-4 d-flex align-items-center justify-content-center">
                <img src={Loginpic} className="profile-image" />
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12 login-details p-4">
                <center className="title mb-3">Member Login</center>
                <input type="text" value={loginPayload.email} onChange={handleChange} name="email" placeholder="email@motivitylabs.com" className="mb-3" />
                <input type="password" name="password" value={loginPayload.password} onChange={handleChange} placeholder="Enter Password" className="mb-4" />
                <button type="submit" className="mb-3 button_login">Login</button>
                <p className="or_text">or</p>
                <div className="px-3">
                  <button onClick={(e) => handleLogin(e, "popup")} className="microsoft_button bg-white ps-1 pe-2 py-2 mb-2 text-secondary w-100">
                    <img className="img_mic me-3" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png" />
                    Sign in with Microsoft
                  </button>
                </div>
                {/* <button onClick={(e) => handleLogin(e,"popup")}  className="mb-3">MS-LOGIN</button> */}
                <p className="" style={{ textAlign: 'center' }}> <a className="btn" onClick={(e) => { navigate("/AccountActivate") }}>Activate Account</a> </p>
                {/* <p className="forgot-credentials text-center">I forgot my login credentials</p> */}
              </div>
            </div>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default Login;

