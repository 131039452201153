import React from 'react'
import wrmsBanner from '../../assets/images/wrms-banner.png';
import wrmsBannerImg from '../../assets/images/wrms-banner-img.png';
import "./Banner.scss";

const Banner = () => {
  return (
    <div>
      <div className='new-banner-section'>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={wrmsBanner} className="d-block ms-auto img-width" alt="" />
            <div className='banner-heading'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12 col-lg-4 col-xs-6 d-none d-lg-flex'>
                    <img src={wrmsBannerImg} className='w-100 h-100' alt='' />
                  </div>
                  <div className='col-12 col-lg-7 col-xs-6 offset-md-1'>
                    <p>Simple. Quick. Secure.</p>
                    <h2 className=''>The complete <span>WRMS</span><br></br> for startups and <br></br> mid-sized Organizations</h2>
                    <ul>
                      <li><button className='border-0 btn ban-btn btn-cost btn-light mt-3'>Get Started</button></li>
                      <li><button className='border-0 btn ban-btn2 btn-cost btn-primary mt-3'>Contact Us</button></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner