import React from 'react'
import JoBPocImage from '../../assets/images/task-panel.png'
import InfoImage from '../../assets/images/wrms-mid.png'
import WrmsMid from '../../assets/images/wrms-mid1.png'
import WrmsMid2 from '../../assets/images/wrms-mid2.png'
import WrmsMid3 from '../../assets/images/wrms-mid3.png'
import WrmsMid4 from '../../assets/images/wrms-low.png'
import WrmsMng1 from '../../assets/images/wrms-mng1.png'
import WrmsMng2 from '../../assets/images/wrms-mng2.png'
import WrmsMng3 from '../../assets/images/wrms-mng3.png'
import WrmsMng4 from '../../assets/images/wrms-mng4.png'
import WrmsBtm1 from '../../assets/images/wrms-btm1.png'
import WrmsBtm2 from '../../assets/images/wrms-btm2.png'
import WrmsBtm3 from '../../assets/images/wrms-btm3.png'
import './HomeContent.scss';

const HomeContent = () => {
    return (
        <div className='HomeContent-section'>
            <div>
                <div>
                    <div className='job_grid'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6 offset-md-1'>
                                    <div className='border-0 mt-4'>
                                        <h2>Leave <span>Management</span></h2>
                                        <p> Define your company leave policy Leave tracking and complete leave accounting Access accurate leave balance and transactions, anytime</p>
                                        <ul>
                                            <li>Fully customizable leave policies.</li>
                                            <li>Holiday lists.</li>
                                            <li>Minimal leave-related tasks and queries.</li>
                                            <li>Leave year-end processing.</li>
                                        </ul>
                                        <button className='border-0 btn dis-btn btn-cost btn-primary mt-3'>Discover More</button>
                                    </div>
                                </div>
                                <div className='col-md-5'> <img src={JoBPocImage} className="img-fluid2 w-80 mt-4" alt="JoBPocImage" /> </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="section-two">
                        <div className="section-two-content col-md-4 col-sm-4 pos-left">
                            <div className="background-image-holder" style={{ background: `url(${TaskView})`, opacity: '1' }}>
                                <img src={TaskView} className="img-fluid" alt="InfoImage" />
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 offset-md-5 col-sm-8 offset-sm-3 ">
                                    <h2> WRMS makes it easy to add modules to your plan as your organization grows.</h2>
                                    <p>Ticketing, email, chat and phone support for the Standard pla. Smart HR management software.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='top_jobs'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-4 offset-md-1'> <img src={InfoImage} className="img-fluid" alt="InfoImage" /> </div>
                                <div className='col-md-5 offset-md-1'>
                                    <div className='border-0 ml-4 '>
                                        <h2 className='mb-4'><span>WRMS</span> makes it easy to add modules to your plan as your organization grows.</h2>
                                        <ul>
                                            <li>
                                                <div className='icon'><img src={WrmsMid} className="img-fluid" alt="WrmsMid" /></div>
                                                <div>
                                                    <h4>Ticketing</h4>
                                                    <p>Offers efficient task management, allowing team members to create, track, and resolve issues with transparency and accountability. Each ticket can be assigned, prioritized, and updated in real-time, ensuring that no request goes unresolved.</p>
                                                    {/* <p className='m-0'><a href='#'>Read more...</a></p> */}
                                                </div> 
                                            </li>
                                            <li>
                                                <div className='icon'><img src={WrmsMid2} className="img-fluid" alt="WrmsMid2" /></div>
                                                <div>
                                                    <h4>Email</h4>
                                                    <p>Automatically syncs emails related to tasks or projects, making all relevant communication accessible within WRMS. This feature reduces the need to toggle between systems, keeping the focus on productivity.</p>
                                                    {/* <p className='m-0'><a href='#'>Read more...</a></p> */}
                                                </div> 
                                            </li>
                                            <li>
                                                <div className='icon'><img src={WrmsMid3} className="img-fluid" alt="WrmsMid3" /></div>
                                                <div>
                                                    <h4>Chat</h4>
                                                    <p>Facilitates instant communication among team members, enabling quick clarifications and collaboration. The chat history is archived within each project or task, providing a comprehensive log of discussions that can be referenced at any time.</p>
                                                    {/* <p className='m-0'><a href='#'>Read more...</a></p> */}
                                                </div> 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className='job_grid2'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-10 offset-md-1'>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <h2>Attendence <span>Management</span></h2>
                                            <p>Capture real-time attendance from Access control / biometric machines</p>
                                        </div>
                                        <div className='col-md-4'>
                                            <button className='border-0 btn dis-btn btn-cost btn-primary mt-3'>Discover More</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 offset-md-1'> <img style={{height: '90%'}} src={WrmsMid4} className="w-100 mt-4" alt="WrmsMid4" /> </div>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                        <ul>
                                            <li>
                                                <div className='icon'><img src={WrmsMng1} className="img-fluid" alt="WrmsMng1" /></div>
                                                <div>
                                                    <h4>Clock-In/Clock-Out Tracking</h4>
                                                    <p>Employees can log their start and end times directly within the system, reducing the need for external tracking tools.</p>
                                                </div> 
                                            </li>
                                            <li>
                                                <div className='icon'><img src={WrmsMng2} className="img-fluid" alt="WrmsMng2" /></div>
                                                <div>
                                                <h4>Automated Leave Requests</h4>
                                                    <p>Team members can submit leave requests directly through WRMS. These requests are routed to the appropriate supervisors for approval, and the system updates schedules accordingly.</p>
                                                </div> 
                                            </li>
                                        </ul>
                                        </div>
                                        <div className='col-md-6'>
                                        <ul>
                                            <li>
                                                <div className='icon'><img src={WrmsMng3} className="img-fluid" alt="WrmsMng3" /></div>
                                                <div>
                                                <h4>Attendance Reports</h4>
                                                    <p>Managers can generate detailed attendance reports, tracking metrics like hours worked, late entries, and absence trends. This ensures that attendance data is readily available for payroll and performance evaluations.</p>
                                                </div> 
                                            </li>
                                            <li>
                                                <div className='icon'><img src={WrmsMng4} className="img-fluid" alt="WrmsMng4" /></div>
                                                <div>
                                                <h4>Shift and Schedule Management</h4>
                                                    <p>Customize schedules, assign shifts, and manage overtime seamlessly, making it easy for managers to plan and adapt as needed.</p>
                                                </div> 
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className='job_grid3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-10 offset-md-1'>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <h2><span>Comprehensive Overview</span> of Reports and Team Management</h2>
                                            <p>Capture real-time attendance from Access control / biometric machines</p>
                                        </div>
                                        <div className='col-md-4'>
                                            <button className='border-0 btn dis-btn2 btn-cost btn-primary mt-3'>Discover More</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-10 offset-md-1'>  

                                <ul>
                                            <li>
                                                <div className='icon'><img src={WrmsBtm1} className="img-fluid" alt="WrmsBtm1" /></div>
                                                <div>
                                                    <h4>My Work Reports</h4>
                                                    <p>WRMS offers tools to track productivity, efficiency, and contributions, with customizable filters for projects, teams, and timelines. Reports can be exported and shared for data-driven decisions.
                                                    </p>
                                                    {/* <button className='border-0 btn dis-btn btn-cost btn-primary mt-3'>Read more</button> */}
                                                </div> 
                                            </li>
                                            <li>
                                                <div className='icon'><img src={WrmsBtm2} className="img-fluid" alt="WrmsBtm2" /></div>
                                                <div>
                                                <h4>Team Work Reports</h4>
                                                    <p>Team Work Reports track team productivity and contributions, with customizable filters for project, member, and timeline. Exportable for data-driven insights.
                                                    </p>
                                                    {/* <button className='border-0 btn dis-btn btn-cost btn-primary mt-3'>Read more</button> */}
                                                </div> 
                                            </li>
                                            <li>
                                                <div className='icon'><img src={WrmsBtm3} className="img-fluid" alt="WrmsBtm3" /></div>
                                                <div>
                                                <h4>Project Reports</h4>
                                                    <p>Project Reports provide insights into progress, efficiency, and resource use. Filter by timeline or team and export for stakeholder updates.
                                                    </p>
                                                    {/* <button className='border-0 btn dis-btn btn-cost btn-primary mt-3'>Read more</button> */}
                                                </div> 
                                            </li>
                                        </ul>

                                </div>
                                {/* <div className='col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                        
                                        </div>
                                        <div className='col-md-6'>
                                        <ul>
                                            <li>
                                                <div className='icon'><img src={WrmsMng3} className="img-fluid" alt="WrmsMng3" /></div>
                                                <div>
                                                <h4>Attendance capture</h4>
                                                    <p>Lorem ipsum dolor sit amet, consectetur
                                                    varius enim in eros elementum tristique. ut commodo diam libero vitae erat.</p>
                                                </div> 
                                            </li>
                                            <li>
                                                <div className='icon'><img src={WrmsMng4} className="img-fluid" alt="WrmsMng4" /></div>
                                                <div>
                                                <h4>Attendance capture</h4>
                                                    <p>Lorem ipsum dolor sit amet, consectetur
                                                    varius enim in eros elementum tristique. ut commodo diam libero vitae erat.</p>
                                                </div> 
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    
                                </div> */}
                                
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default HomeContent