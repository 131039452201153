import { configureStore } from "@reduxjs/toolkit";
import WRMSAppSlice from './reducer'

export const Store = configureStore({
  reducer: {
    WRMSAppSlice,
  },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
});