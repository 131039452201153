import { Box, Checkbox, Typography,Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxios from "../../helpers/Hooks/useAxios";
import { URLS } from "../../helpers/URLS";
import {  Container } from "react-bootstrap";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmpTimeSheetReport from "../EmpTimeSheetReport";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedEmpsForTimeSheetApprove } from "../../Redux/reducer";
import './style.css'


const TeamTimeSheets = ({ userInfo }) => {
const { fetchData } = useAxios();
const [empTimeSheetReports , setEmpTimeSheetReports]=useState([])
const [selectedEmps , setSelectedEmps]=useState([])
const { selectedEmpsForTimeSheetApprove } = useSelector((state) => {
    return state.WRMSAppSlice;
  });

const dispatch=useDispatch()

  useEffect(() => {
    getTeamTimeSheets();
  }, []);

  const getTeamTimeSheets = async () => {
    try {
      let teamReports = await fetchData({
        url: URLS.TeamTimeSheets,
        method: "get",
      });
      //console.log(teamReports, "teamReports");
      handelSegrigateBasedOnEmp(teamReports)
    } catch (error) {
      console.log(error, "error");
    }
  };

const handelSegrigateBasedOnEmp=async(data)=>{
try{
    let empArray = [];
    data?.map((each)=>{
        if(empArray?.find((emp)=>emp?.EmpName == each?.employeeName)){
          let indexOfExistingEmp= empArray?.findIndex((empData)=>empData?.EmpName)
          if(indexOfExistingEmp == -1){
            return 
          }
          empArray[indexOfExistingEmp].reports= [...empArray[indexOfExistingEmp].reports , each]
        }
        else{
            empArray.push({EmpName:each?.employeeName , reports:[each] })
        }
    })
    setEmpTimeSheetReports(empArray)
    //console.log(empArray , 'empwise')
}
catch(error){
console.log(error , 'error')
}
} 

const handleCheckboxClick = (e, empName) => {
const isChecked = e.target.checked;
let updatedEmps = [...selectedEmps] ;


if(isChecked && !updatedEmps.includes(empName)){
    updatedEmps.push(empName)
}
else{
    updatedEmps = updatedEmps.filter((emp) => emp !== empName);
}
// console.log(updatedEmps , 'updatedEmps')
setSelectedEmps([...updatedEmps])
dispatch(setSelectedEmpsForTimeSheetApprove(updatedEmps))
};



const handleApproveAll = async()=>{
    try{
//console.log(selectedEmpsForTimeSheetApprove , 'selectedEmpsForTimeSheetApprove')
let body={selectedEmps:selectedEmpsForTimeSheetApprove}
let approveAll = await fetchData({url:URLS.ApproveAll , method:'put' , body:body})
getTeamTimeSheets()
dispatch(setSelectedEmpsForTimeSheetApprove([]))
    }
    catch(error){

    }
}



  return <Container>
     {selectedEmpsForTimeSheetApprove && selectedEmpsForTimeSheetApprove.length ? 
     <Box className="approve-all">
     <Button className="sbtn" size="small" onClick={()=>handleApproveAll()}>Approve All</Button>
     </Box>
     :null}
    {empTimeSheetReports && empTimeSheetReports.length ? empTimeSheetReports.map((empData)=>{
        return <Accordion >
        <AccordionSummary
         expandIcon={<ExpandMoreIcon  />}
          aria-controls={`panel1-content-${empData?.EmpName}`}
          id={`panel1-header-${empData?.EmpName}`}
        >
       <Box sx={{display:"flex" , gap:1, alignItems:"center"}}>
       <Checkbox 
       onClick={(e)=>(e.stopPropagation())} 
        onChange={(e)=>handleCheckboxClick( e,empData?.EmpName)}
        />
        <Typography>{empData?.EmpName}</Typography>
        </Box> 
        </AccordionSummary>
        <AccordionDetails>
      <EmpTimeSheetReport  reportData={empData?.reports} />
        </AccordionDetails>
      </Accordion>
    })
    
    : <Box>
        <Typography>No Pending Reports</Typography>
    </Box>
    
    }
    
  </Container>
  
};

export default TeamTimeSheets;
